import { useState } from "react";
import Organigram from '../img/Organigram.png';
import GFX15878 from '../img/GFX15878.png';
import GFX16228 from '../img/sfeerfoto.jpg';
import Groepsfoto_enthousiast from '../img/Groepsfoto_enthousiast.jpg';
import XT3A1182 from '../img/XT3A1182_lower_res.jpg';
import Checklist from "./Checklist";
import Icon from './Icon';


function Content(props) {

    const [answer1, setAnswer1] = useState();
    const [answer2, setAnswer2] = useState();
    const [answer3, setAnswer3] = useState();
    const [answer4, setAnswer4] = useState();
    const [answer5, setAnswer5] = useState();

    const hrContacts = props.contactsData?.data?.filter(contact => contact.type === "hr");
    const cocecos = props.contactsData?.data?.filter(contact => contact.type === "coceco");
    const admins = props.contactsData?.data?.filter(contact => contact.function.toLowerCase().includes('management assistant'));

    function convertMailAddress(email) {
        return 'mailto:' + email;
    }

    function showAnswer(id) {
        switch (id) {
            case 1:
                setAnswer1(!answer1);
                break;
            case 2:
                setAnswer2(!answer2);
                break;
            case 3:
                setAnswer3(!answer3);
                break;
            case 4:
                setAnswer4(!answer4);
                break;
            case 5:
                setAnswer5(!answer5);
                break;
            default:
                break;

        }
    }

    let returnedElem = (

        <div>
            <section className="content-container no-padding">
                <section className="fullwidth">
                    <h1>Dit is UniPartners</h1>
                    <div className="organigram-container">
                        <img src={Organigram} className="organigram" alt="Organigram" />
                    </div>
                </section>
                <section>

                    <div className="img-container recruitment-container">
                        <div>
                            <h2>Recruitment & sales</h2>
                            <p>Yoni Daniels en Sara Van Obbergen maken deel uit van het recruitment team. Zij zijn samen verantwoordelijk voor de werving en selectie van nieuwe IT en Finance consultants.</p>
                            <p>Brigitte en Jorn zijn verantwoordelijk voor de sales. Brigitte neemt de finance tak op zich en Jorn doet hetzelfde voor IT. Zij vormen de brug tussen onze partners en klanten aan de ene kant en de consultants aan de andere kant. Voor contractverlenging zal je voornamelijk met hen contact hebben.</p>
                        </div>
                        <img src={Groepsfoto_enthousiast} alt="foto recruitment & sales"></img>
                    </div>
                </section>
                <section>
                    <div className="img-container admin-container">
                        <img src={GFX15878} alt="foto admin"></img>
                        <div>
                            <h2>Administratie</h2>
                            <p>Katty Van Dongen en Leen Van Den Auwera vormen samen het administratief team. Als Management Assistants nemen zij het administratieve luik voor hun rekening. Katty ondersteunt de IT-consultants en de medewerkers van de UniPartners Group, terwijl  Leen zich richt op de Finance consultants. Hun taken omvatten onder andere loon administratie, verwerking van verlof/ADV/overuren/..., facturatie, timesheets en afwezigheden.  </p>
                            <section className="personalised-block">
                                {admins?.map(contact => {
                                    return (<>
                                        <b>{contact.firstName} {contact.lastName}</b>
                                        <ul>
                                            <li key={contact.email}>Email: <a href={convertMailAddress(contact.email)}>{contact.email}</a></li>
                                            {contact.telephone.map(tel => {
                                                if (tel.visible === 1) {
                                                    return (
                                                        <li key={tel.number}>Tel. {tel.number}</li>
                                                    )
                                                }
                                                return null;
                                            })}
                                            <li>
                                                <a
                                                    href={`https://teams.microsoft.com/l/chat/0/0?users=${contact.email}`}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className='Teams'>
                                                    <Icon icon='teams' className='teams' />
                                                    <span className="teams-label" aria-hidden='true'>Teams</span>

                                                </a></li>
                                        </ul>

                                    </>)
                                })}
                            </section>
                        </div>
                    </div>
                </section>
            </section>

            <section id="begin" className="white-background">
                <section className=" content-container">

                    <h1><i className="ri-door-open-line"></i>Voorbereiding op je eerste werkdag</h1>
                    <h4>
                        Je contract is getekend, welke stappen worden er nu al ondernomen?
                    </h4>

                    <div>
                        <h2>Voorbereiding</h2>
                        <p>Binnenkort wordt er een unipartners.org mailadres aangemaakt waar alle communicatie naartoe gestuurd zal worden. Hieronder vallen UniPartners mails, projectaanvragen, uitnodigen voor events en andere relevante berichten. Bovendien krijg je ook een login voor <a href="https://ketchup.unipartners.org" target="_blank" rel="noreferrer">KetchUP</a> en wordt er een Teams-account voor je aangemaakt.</p>
                        <p>Ongeveer 1 week voor je in dienst treedt, ontvang je een mail om een onthaalgesprek in te plannen. Dit gesprek duurt ongeveer 1 uur en nadien kan je ook je wagen ophalen. </p>
                    </div>

                    <Checklist />

                    <h1 id="eerstedag"><i className="ri-pencil-line"></i>Je eerste werkdag</h1>
                    <h4>
                        Gefeliciteerd, je bent nu een officiële UniPartner! Wat staat je allemaal te wachten?
                    </h4>

                    <div>
                        <div className="img-container interview-container">
                            <div>
                                <h2>Gesprekstechnieken</h2>
                                <span>Een paar weken voor je daadwerkelijke startdatum zal Bart, Sara of Yoni je uitnodigen voor een meeting over gesprekstechnieken. Tijdens dit gesprek worden handige tips & tricks besproken die je achteraf kan gebruiken tijdens je projectgesprekken. Tegen dan mag je een korte voorstelling maken in Word waarin je jezelf voorstelt, je opleiding, relevante werkervaring, je toekomstplannen, je sterke punten, en andere relevante informatie deelt. Bart, Sara of Yoni zullen dit samen met jou doornemen en je hierbij begeleiden, zodat je goed voorbereid aan je gesprekken kan beginnen.</span>
                            </div>

                            <img src={GFX16228} alt="Galina"></img>
                        </div>
                    </div>
                </section>

            </section>

            <section id='bank' className="content-container">
                <h1><i className="ri-home-line" />Op de bank</h1>
                <p>
                    Om te vermijden dat jij als vaste medewerker zonder werk valt kijken we één maand voor het beëindigen van je project alvast uit naar een nieuwe opdracht. Loopt je huidige project toch af voor zich een nieuwe opdracht aandient, dan werk je mee aan een intern project of volg je <a href="#ontwikkeling">interessante cursussen</a> die een meerwaarde voor je profiel zijn.
                </p>
                <p>We verwachten wel dat je altijd bereikbaar bent zowel via teams als via je UP mailadres en dat je zo snel mogelijk reageert op doorgestuurde aanvragen.  </p>
            </section>
            <section id="project" className="white-background">
                <section className="content-container">
                    <h1><i className="ri-building-line"></i> Op een project</h1>
                    <h4>
                        Het is zover! Je begint aan een nieuw project. Hieronder beschrijven we de belangrijkste dingen waar je op moet letten.
                    </h4>
                    <section className="block-container">
                        <section>
                            <div className="icon"><i className="ri-file-list-line"></i></div>
                            <h2 id="timesheet">Timesheet</h2>
                            <p>Je vult zoals altijd op de laatste dag van de maand jouw <a href="https://ketchup.unipartners.org/timesheet/urenregistratie" target="_blank" rel="noreferrer">timesheet</a> in op KetchUP, maar vanaf nu moet je ook de timesheet van de eindklant invullen. Nadat je alles zorgvuldig gecontroleerd hebt, laat je deze ondertekenen door de eindklant en stuur je hem door naar <a href="mailto:timesheets@unipartners.be">timesheets@unipartners.be</a>.</p>
                        </section>
                        <section>
                            <div className="icon"><i className="ri-capsule-fill"></i></div>
                            <h2 id="ziekte">Ziekte</h2>
                            <p>Je bent verplicht UniPartners en de klant onmiddelijk op de hoogte te brengen van jouw arbeidsongeschiktheid en dit vóór 10:00 uur.</p>
                            <p>Vul je <a href="https://ketchup.unipartners.org/timesheet/ziekte-meldingen" target="_blank" rel="noreferrer">ziektemelding</a> in op KetchUP. Bezorg je geneeskundig getuigschrift binnen de 48 uur. Vergeet jouw ziekte ook niet in te vullen bij jouw afwezigheden!</p>
                        </section>
                    </section>
                </section>
            </section>
            <section id="ontwikkeling" className="content-container">
                <h1><i className="ri-seedling-line"></i> Jouw ontwikkeling</h1>
                <p>
                    We vinden het uiteraard belangrijk dat alle consultants hun kennis en skills blijven ontwikkelen en up-to-date houden. Daarom bieden we klassikale en individuele mogelijkheden aan om je kennis bij te schaven, zowel voor soft skills als hard skills.
                </p>

                <div>
                    <div className="training-container img-container">
                        <div>
                            <section>
                                <h2>Opleidingen</h2>
                                <section>
                                    Je hebt recht op 6 opleidingsdagen per 2 jaar waarvan er 1 traditioneel elk jaar voorzien wordt voor een teambuilding. Zo lang je geen project hebt, mag je je inschrijven voor dagopleidingen bij Cevora. Vergeet zeker niet de cursus te annuleren mocht je niet aanwezig kunnen zijn, zo wordt er geen kost van €75 doorgerekend. Eens je op project zit, wordt er gekeken hoeveel opleidingsdagen reeds opgenomen zijn en kan je ADV-dagen of verlofdagen inzetten om een opleiding te volgen. Je kan je ook inschrijven voor online-cursussen die je op een tijdstip naar voorkeur kan volgen.

                                    <ul>
                                        <li>Online opleidingen: Als je graag toegang krijgt tot <a href="https://www.pluralsight.com" target="_blank" rel="noreferrer">Pluralsight</a>, <a href="https://www.examcollection.com/" target="_blank" rel="noreferrer">Exam Collection</a> of <a href="https://www.cbtnuggets.com/" target="_blank" rel="noreferrer">CBT Nuggets</a>, laat het ons zeker weten dan bezorgen we je de inloggegevens.</li>
                                        <li>UP opleidingen: Deze worden georganiseerd door je coach. Je kan ze altijd op <a href="https://ketchup.unipartners.org/opleidingen" target="_blank" rel="noreferrer">KetchUP</a> terugvinden.</li>
                                        <li>Externe opleidingen: Die kan je o.a. via de website van <a href="https://www.cevora.be" target="_blank" rel="noreferrer">Cevora</a>  of <a href="https://www.vdab.be/opleidingen/aanbod?p=1" target="_blank" rel="noreferrer">VDAB: Vind een opleiding | VDAB</a> vinden.</li>
                                    </ul>

                                </section>
                            </section>
                        </div>
                        <img src={XT3A1182} alt="foto opleiding"></img>
                        <section>
                            <h2>Competence Center Coach (CoCeCo)</h2>
                            <p>De competence center coach is, net als jij, een consultant bij UniPartners, maar hij heeft een extra rol als competence center coach. Deze persoon zal jouw technische ontwikkeling (op)volgen. Ieder jaar wordt er een functioneringsgesprek gehouden. Je kan bij deze persoon terecht voor vragen met betrekking tot opleidingen of technische problemen op je project.
                            </p>
                            {cocecos && cocecos.length > 0 && (

                                <section className="personalised-block">
                                    <p>Jouw CoCeCo:</p>
                                    {cocecos?.map(contact => {
                                        return (<><span key={contact.id}>
                                            <b>{contact.firstName} {contact.lastName}</b>
                                            <ul>
                                                <li key={contact.email}>Email: <a href={convertMailAddress(contact.email)}>{contact.email}</a></li>
                                                {contact.telephone.map(tel => {
                                                    if (tel.visible === 1) {
                                                        return (
                                                            <li key={tel.number}>Tel. {tel.number}</li>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <li>

                                                    <a
                                                        href={`https://teams.microsoft.com/l/chat/0/0?users=${contact.email}`}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        className='Teams'>
                                                        <Icon icon='teams' className='teams' />
                                                        <span className="teams-label" aria-hidden='true'>Teams</span>

                                                    </a>
                                                </li>
                                            </ul>
                                        </span></>
                                        )
                                    })}
                                </section>
                            )}
                        </section>

                    </div>
                    <div>
                        <h2>HR-verantwoordelijke</h2>
                        <p>
                            Je HR-verantwoordelijke is een persoon uit het HR-team dat jou persoonlijk zal begeleiden. Hij of zij is je eerste aansprekingspunt en staat klaar om al je vragen te beantwoorden. Bovendien zullen ze je loopbaan op bij Unipartners opvolgen en jaarlijks een functioneringsgesprek met je voeren.

                        </p>
                        {hrContacts && hrContacts.length > 0 && (

                            <div className="personalised-block">
                                <p>Jouw HR-verantwoordelijke:</p>
                                {hrContacts?.map(contact => {
                                    return (<><span key={contact.id}>
                                        <b>{contact.firstName} {contact.lastName}</b>
                                        <ul>
                                            <li key={contact.email}>Email: <a href={convertMailAddress(contact.email)}>{contact.email}</a></li>
                                            {contact.telephone.map(tel => {
                                                if (tel.visible === 1) {
                                                    return (
                                                        <li key={tel.number}>Tel. {tel.number}</li>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <li>

                                                <a
                                                    href={`https://teams.microsoft.com/l/chat/0/0?users=${contact.email}`}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className='Teams'>
                                                    <Icon icon='teams' className='teams' />
                                                    <span className="teams-label" aria-hidden='true'>Teams</span>
                                                </a>

                                            </li>
                                        </ul>
                                    </span>
                                    </>
                                    )
                                })}
                            </div>
                        )
                        }
                    </div>
                </div>
            </section>
            <section id="praktisch" className="white-background">
                <section className="content-container">

                    <h1><i className="ri-file-line"></i> Praktisch</h1>
                    <div>
                        <section className="block-container-schade">
                            <section>
                                <div className="icon"><i className="ri-car-line"></i></div>
                                <h2 id="ongeval">Schade</h2>
                                <p><b>Zorg eerst dat jij en andere betrokkenen veilig zijn.</b></p>
                                <p>Je vindt de <a href="https://ketchup.unipartners.org/wagen/ongeval" target="_blank" rel="noreferrer">schademelding</a> terug op KetchUP. Hier staat welke stappen je moet ondernemen. Je vindt hier de nodige formulieren in voor schade, panne of glasschade. Indien iets niet duidelijk is of er iets anders aan de wagen scheelt kan je jouw HR-verantwoordelijke contacteren.</p>
                            </section>
                        </section>
                        <h2>Cafetariaplan</h2>
                        <div>
                            Telkens in januari kan je met je eindejaarspremie die je datzelfde jaar opbouwt, kiezen voor volgende voordelen:

                            <ul>
                                <li>Tandzorg, ambulante zorgen en hospitalisatie familieleden</li>
                                <li>Individueel pensioensparen tbv max. 990€/jaar</li>
                                <li>Aankoop van gsm, tablet of laptop</li>
                                <li>...</li>
                            </ul>
                        </div>
                        <div>
                            Goed om te weten:
                            <ul>
                                <li>Je bent vrij om te kizen of je deelneemt aan het cafetariaplan.</li>
                                <li>Het cafetariabudget is begrensd tot je eindejaarspremie, dus zodra je nettobedrag na cafetariaplan in negatief gaat moet je je keuze wat aanpassen.</li>
                                <li>Jouw keuze wordt in een contract vastgelegd.</li>
                                <li>Indien je minder dan 5 jaar anciënniteit hebt vervalt het recht op het cafetariaplan bij ontslag of vroegtijdig vertrek.</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h2>Aanbrengpremie</h2>
                        <p>Bij Unipartners verwelkomen we graag talent! Ken jij iemand met het IT of Finance profiel dat wij zoeken? Kan je die persoon warm maken om te werken voor UniPartners? Als die kennis van jou een match blijkt te zijn, krijg je als ‘aanbrenger’ een mooie premie van 2000€ (bruto) als die persoon minder dan 3 jaar ervaring heeft, en 4000€ (bruto) bij meer dan 3 jaar ervaring. Een eerste schijf wordt betaald 1 maand na indiensttreding van de nieuwe medewerker en de volgende schijf na 6 maanden.
                        </p>
                    </div>
                    <div>
                        <h2>Hier vind je alles terug:</h2>
                        <ul>
                            <li>De onderstaande documenten vind je terug op <a target="_blank" rel="noreferrer" href="https://ketchup.unipartners.org/documenten">KetchUP</a>:
                                <ul>
                                    <li>Arbeidsreglement</li>
                                    <li>Car policy</li>
                                    <li>Mobiliteitsbudget</li>
                                    <li>Hospitalisatiepolis</li>
                                    <li>Groepsverzekering</li>
                                    <li>Feestdagen</li>
                                    <li>...</li>
                                </ul>
                            </li>
                            <li><a href="https://ketchup.unipartners.org/timesheet/urenregistratie" target="_blank" rel="noreferrer">Afwezigheden en timesheets</a></li>
                            <li><a href="https://ketchup.unipartners.org/timesheet/ziekte-meldingen" target="_blank" rel="noreferrer">Ziektemelding</a></li>
                            <li><a href="https://ketchup.unipartners.org/wagen/ongeval" target="_blank" rel="noreferrer">Schade aangifte</a></li>
                            <li><a href="https://ess.partena.be/UNIH_400017" target="_blank" rel="noreferrer">Loonbrief</a></li>
                            <li><a href="https://www.monizze.be/nl/" target="_blank" rel="noreferrer">Ecocheques</a></li>
                            <li><a href="https://unipartners.benefitsatwork.be" target="_blank" rel="noreferrer">Benefits@work</a></li>
                            <li><a href="https://www.office.com/?auth=2" target="_blank" rel="noreferrer">Office 365</a></li>
                        </ul>
                    </div>
                </section>
            </section>
            <section id="hulp" className="content-container">
                <h1><i className="ri-question-mark"></i> FAQ</h1>
                <ul className="collapser">
                    <li>
                        <p onClick={() => showAnswer(1)}>Wat als ik mijn inloggegevens van Monizze ben vergeten?</p>
                        <span className={!answer1 ? "answer hidden" : "answer"}>Surf naar de site van <a href="https://my.monizze.be/fr/login" target="_blank" rel="noreferrer">Monizze</a> en klik bij het aanmelden op 'Je wachtwoord vergeten?'.</span>
                    </li>
                    <li>
                        <p onClick={() => showAnswer(2)}>Ik moet gehospitaliseerd worden. Wat nu?</p>
                        <span className={!answer2 ? "answer hidden" : "answer"}>Alle documenten i.v.m. je hospitalisatieverzekering vind je terug op <a href="https://ketchup.unipartners.org/documenten">KetchUP</a> onder 'Werking UP'. Bij vragen kan je altijd jouw HR-verantwoordelijke contacteren.</span>
                    </li>
                    <li>
                        <p onClick={() => showAnswer(3)}>Waar vind ik terug hoeveel verlofdagen ik nog beschikbaar heb?</p>
                        <span className={!answer3 ? "answer hidden" : "answer"}>Je kan deze terugvinden in jouw <a href="https://ketchup.unipartners.org/timesheet/afwezigheden" target="_blank" rel="noreferrer">timesheet</a>. Klik op de vakantieteller om te zien hoeveel en welke verlofdagen je nog over hebt.</span>
                    </li>
                    <li>
                        <p onClick={() => showAnswer(4)}>Wat gebeurt er als ik zonder project zit?</p>
                        <span className={!answer4 ? "answer hidden" : "answer"}>Kijk eerst eens <a href="#bank">deze</a> rubriek na, indien je nog met vragen zit kan je je CoCeCo of HR verantwoordelijke raadplegen.</span>
                    </li>
                    <li>
                        <p onClick={() => showAnswer(5)}>Wat moet ik doen als ik pech of een ongeval heb met mijn wagen?</p>
                        <span className={!answer5 ? "answer hidden" : "answer"}>Zorg er eerst voor dat jijzelf en de andere betrokkenen veilig zijn. Je vindt de <a href="https://ketchup.unipartners.org/wagen/ongeval" target="_blank" rel="noreferrer">schademelding</a> terug op KetchUP. Hier staat welke stappen je moet ondernemen. Je vindt hier de nodige formulieren in voor schade, panne of glasschade. Indien iets niet duidelijk is of er iets anders aan de wagen scheelt kan je jouw HR-verantwoordelijke contacteren.</span>
                    </li>
                </ul>
            </section>
        </div >





    )

    return returnedElem
}
export default Content;

